import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FaPhone } from 'react-icons/fa';
import { above, colors } from '../styles/utilities';
import Logo from './Logo';

const Footer = props => {

  const menu = (m) => {
    return m.map((mi, idx) => {
      if (mi.link !== undefined) {
        if (mi.link_type && mi.link_type === 'external') {
          return <li key={idx}><a href={mi.link} target="_blank" rel="noopener noreferrer">{mi.name}</a></li>
        }
        return <li key={idx}><Link to={mi.link}>{mi.name}</Link></li>
      }
      else if (mi.sub_menu !== undefined) {
        return (
          <li key={idx}>
            <p><strong>{mi.name}</strong></p>
            <ul className="submenu">
              {menu(mi.sub_menu)}
            </ul>
          </li>
        )
      }
      return null;
    });
  };

  return (

    <footer className={`${props.className} site-footer`}>
      <div className="footer--top">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-lg-3 logo">
              <Link to="/">
                <Logo />
              </Link>
              <p className="address">
                3501 South Tamiami Trail<br />
                Suite 302<br />
                Sarasota, FL 34239
              </p>
            </div>
            <div className="col-xs-12 col-lg-6 menu">
              <ul>
                {menu(props.menu.frontmatter.menu)}
              </ul>
            </div>
            <div className="col-xs-12 col-lg-3 phone-column">
              <a 
                href={`tel:${props.phoneNumber}`} 
                className="phone phone--styled ga-on-click-and-enter"
                ga='[
                  "event", "click", {
                    "event_category": "phone",
                    "event_label": "footer-phone"
                  }
                ]'
              >
                <FaPhone />
                {props.phoneNumber}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer--bottom">
        <p>&copy; { new Date().getFullYear() } Tille Eye Care</p>
      </div>
    </footer>

  )

}

export default styled(Footer)`

  .footer--top {

    background-color: ${colors.purpleDark};
    //margin-top: 75px;
    padding-bottom: 25px;
    padding-top: 25px;

    a {
      color: #fff;
      text-decoration: none;
    }

  }

  .logo {
    text-align: center;
    .gatsby-image-wrapper {
      margin: 0 auto;
      ${above.lg`
        margin: 0;
      `}
    }
  }

  .gatsby-image-wrapper {
    max-width: 100%;
  }

  .menu {
    color: #fff;
    font-size: 0.8rem;
    margin: 15px 0 0 0;
    text-align: left;

    ${above.lg`
      margin: 0;
    `}

    ul {
      align-items: flex-start;
      display: flex;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;

      ${above.lg`
        justify-content: flex-start;
      `}

      li {
        display: inline-block;
        margin: 0 15px;
        ${above.xl`
          margin: 0 30px;
        `}
        a:hover,
        a:active,
        a:focus {
          text-decoration: underline;
        }
      }
    }

    p {
      margin: 0;

      strong {
        font-size: 1rem;
      }
    }

    .submenu {
      display: block;
      li {
        display: block;
        margin: 0;
      }
    }

  }

  .footer--bottom {
    font-size: 0.75rem;
    padding: 10px 0;
    text-align: center;
    p {
      margin: 0;
    }
  }

  .address {
    color: #fff;
    font-size: 0.9rem;
    margin-top: 5px;
    text-align: center;
    ${above.lg`
      text-align: left;
    `}
  }

  .phone-column {
    margin-top: 30px;
    text-align: center;
    ${above.lg`
      margin-top: 0;
      text-align: right;
    `}
  }

  a.phone {
    background-color: #fff;
    border-radius: 2px;
    color: ${colors.purpleDark};
    margin-left: 15px;
    padding: 10px;
    ${above.lg`
      cursor: default;
      pointer-events: none;
    `}
  }

`;