import React from 'react';
import { Link } from 'gatsby';
import MainHeader from './MainHeader';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import { above } from '../styles/utilities';

const Hero = props => {

  let isHome = props.className.match(/hero--home/);

  return (

  <section className={ 'section--hero ' + props.className }>

    <div className="overlay hero__bg-img">
      <Img
        loading="eager"
        alt={props.hero.image_alt}
        fluid={props.hero.image.childImageSharp.fluid} 
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          width: '100%'
        }}
        durationFadeIn={250}
      />
    </div>
    <div className="overlay hero__bg-filter"></div>

    <MainHeader
      menu={props.menu}
      toggleMainNavShelf={props.toggleMainNavShelf}
      phoneNumber={props.phoneNumber}
    />

    <div className="container content-container">
      <div className="hero__content">
        <div>
          { 
            isHome 
              ? <h1>{props.hero.heading}</h1>
              : <h2>{props.hero.heading}</h2>
          }
          <p>{props.hero.text}</p>
        </div>
        <div>
          {
            props.hero.link_type === 'external' 
            ? <a 
                href={props.hero.button_link}
                className="btn btn--green ga-on-click-and-enter"
                target="_blank"
                rel="noopener noreferrer"
                ga='[
                  "event", "click", {
                    "event_category": "appointment",
                    "event_label": "hero-appointment-button"
                  }
                ]'
              >{props.hero.button_text}</a>
            : <Link className="btn btn--green" to={props.hero.button_link}>{props.hero.button_text}</Link>
          }
        </div>
      </div>
    </div>

  </section>
  )

}

export default styled(Hero)`
    
  color: #fff;
  position: relative;
  text-align: center;
  
  ${above.md`
    text-align: left;
  `}

  h1, .h1 , h2, .h2 {
    color: #fff;
  }

  h2, .h2 {
    font-size: 2.25rem;
  }

  .hero__bg-filter {
    background-color: #34344A;
    opacity: .8;
  }

  .content-container {
    padding-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;

    ${above.md`
    justify-content: flex-start;
    `
  }
  }

  &.hero--home {
    .content-container {
      min-height: 500px;
    }
  }

  &.hero--default {
    .content-container {
      min-height: 250px;
    }
  }

  .hero__content {
    max-width: 850px;
  }

`;