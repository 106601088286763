import React from 'react';
import { Link } from 'gatsby';
import { FaAngleDown } from 'react-icons/fa';
import {useSpring, animated} from 'react-spring';

export default props => {

  const menu = (m) => {
    return m.map((mi, idx) => {
      if (mi.link !== undefined) {
        if (mi.link_type && mi.link_type === 'external') {
          return <li key={idx}><a href={mi.link} target="_blank" rel="noopener noreferrer">{mi.name}</a></li>
        }
        return <li key={idx}><Link to={mi.link}>{mi.name}</Link></li>
      }
      else if (mi.sub_menu !== undefined) {
        return (
          <li key={idx}>
            <button onClick={e => props.toggleSubmenu(e, mi.name)}>
              {mi.name} <FaAngleDown />
            </button>
            <animated.ul
              // style={style(mi.name)}
              style={(() => {
                if (props.activeSubmenuName === mi.name) {
                  return useSpring({
                    display: 'block',
                    marginTop: 15,
                    opacity: 1,
                    from: {
                      marginTop: 50,
                      opacity: 0,
                    }
                  });
                }
                else if (props.turnOff === mi.name) {
                  return useSpring({
                    display: 'none',
                    marginTop: 50,
                    opacity: 0,
                    //transitionDelay: '1s',
                    //transitionProperty: 'display',
                    zIndex: -999,
                    from: {
                      //display: 'block',
                      marginTop: 15,
                      opacity: 1,
                    }
                  });
                }
                return useSpring({
                  display: 'none',
                  marginTop: 50,
                  opacity: 0,
                  from: {
                    marginTop: 10,
                    opacity: 1,
                  }
                });
              })()}
              className="submenu"
            >
              {menu(mi.sub_menu)}
            </animated.ul>
          </li>
        )
      }
      return null;
    });
  };

  return menu(props.menu.frontmatter.menu);

}