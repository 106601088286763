export const colors = {
  purpleDark: "#34344A",
  purpleMid: "#4D4D6D",
  grayLight: "#EDF2F4",
  //grayMid: "#8D99AE",
  grayMid: "#B5BABC",
  orangePastel: "#E88873",
  greenLight: "#14CC60",
  greenBright: "#00FF67",
  blueBright: "#0cb6ff",
  greenDark: 'hsla(145, 100%, 9%, 1)',
  red: '#DC2A2A'
};