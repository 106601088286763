import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { MdMenu } from 'react-icons/md';
import { above, colors } from '../styles/utilities';
import { FaPhone } from 'react-icons/fa';
import Logo from './Logo';
import MainMenu from './MainMenu';

class MainHeader extends React.Component {

  render() {
    return (
      <header className={ `container ${this.props.className}` }>
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/">
            <Logo />
          </Link>
          <nav className="nav--inline main-nav">
            <MainMenu menu={this.props.menu} />
            <span className="phone phone--styled">
              <FaPhone />
              {this.props.phoneNumber}
            </span>
          </nav>
          <button className="navicon" onClick={this.props.toggleMainNavShelf}>
            <MdMenu />
          </button>
        </div>
      </header>
    )
  }

}

export default styled(MainHeader)`

    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;

    .main-nav {

      display: none;
      z-index: 9;

      ${above.lg`
        display: block;
      `}

      button {
        background-color: transparent;
        border: none;
        color: #fff;
        margin: 0 5px;
        padding: 10px;
        transition: all 0.2s ease-in-out;

        &:active,
        &:focus,
        &:hover {
          background-color: #fff;
          color: ${colors.purpleDark};
        }
      }

      li {
        position: relative;
      }

      .phone--styled {
        background-color: #fff;
        border-radius: 2px;
        color: ${colors.purpleDark};
        margin-left: 45px;
        padding: 10px;
      }
  
      .submenu {
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 0 1px ${colors.purpleDark};
        //display: none;
        left: 0;
        margin: 5px 5px 0 5px;
        min-width: 200px;
        opacity: 0;
        position: absolute;
        //visibility: hidden;
        //z-index: 0;

        &:before {
          background-color: #fff;
          border-radius: 2px;
          box-shadow: 0 0 1px ${colors.purpleDark};
          content: "";
          display: inline-block;
          height: 10px;
          left: 30px;
          position: absolute;
          top: -5px;
          transform: rotate(45deg);
          width: 10px;
          z-index: 1;
        }

        li {
          padding: 0;
          width: 100%;
        }

        a {
          color: ${colors.purpleDark};
          display: block;
          margin: 0;
          padding: 10px;
          //transition: all 0.1s ease-in-out;
          width: 100%;

          &:active,
          &:focus,
          &:hover {
            background-color: ${colors.blueBright};
            color: #fff;
          }
        }
      }


    }

    .navicon {
      background: none;
      border: none;
      color: #fff;
      font-size: 2rem;
      ${above.lg`
        display: none;
      `}
    }

`;