import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { above, colors } from '../styles/utilities';

const Location = props => (

  <section className={ props.className }>
    <div className="section--office-hours">
      <div className="container">

        <div className="row justify-content-between">

          <div className="col-xs-12 col-lg-5">
            <div dangerouslySetInnerHTML={{__html:props.location.html}}></div>
            { 
              props.location.frontmatter.button_type && props.location.frontmatter.button_type === 'external'
                ? <a 
                  href={props.location.frontmatter.button_link}
                  className="btn btn--purple ga-on-click-and-enter"
                  target="_blank"
                  rel="noopener noreferrer"
                  ga='[
                    "event", "click", {
                      "event_category": "appointment",
                      "event_label": "office-location-appointment"
                    }
                  ]'
                >{props.location.frontmatter.button_text}</a>
                : <Link to={props.location.frontmatter.button_link} className="btn btn--purple">{props.location.frontmatter.button_text}</Link>
            }
          </div>

          <div className="d-flex flex-wrap col-xs-12 col-lg-7" dangerouslySetInnerHTML={{__html:props.location.frontmatter.map}}></div>

        </div>
      </div>
    </div>
  </section>

)

export default styled(Location)`

  background-color: ${ colors.grayLight };
  padding: 75px 0;
  text-align: center;

  ${above.lg`
    text-align: left;
  `}

  h2 {
    color: ${ colors.purpleDark };
  }

  .btn {
    margin-bottom: 30px;
  }


`;