import React from 'react';
import MainMenuAnimated from './MainMenuAnimated';

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.handleBodyClick = this.handleBodyClick.bind(this);
  }

  state = {
    turnOff: null,
    firstToggle: false,
    activeSubmenu: null,
    activeSubmenuName: null,
  };

  toggleSubmenu = (e, menuName) => {

    if (!this.state.firstToggle) {
      this.setState({
        firstToggle: true,
        activeSubmenu: e.target, 
        activeSubmenuName: menuName, 
        turnOff: null
      });
    }

    else if (menuName === this.state.activeSubmenuName) {
      this.setState({
        firstToggle: true,
        turnOff: menuName,
        activeSubmenu: e.target,
        activeSubmenuName: null
      });
    }

    else {
      this.setState({
        firstToggle: true,
        turnOff: this.state.activeSubmenuName,
        activeSubmenuName: menuName,
        activeSubmenu: e.target
      });
    }

  }

  componentDidMount() {
    document.querySelector('body').addEventListener('click', this.handleBodyClick);
  }

  componentWillUnmount() {
    document.querySelector('body').removeEventListener('click', this.handleBodyClick);
  }

  handleBodyClick = (e) => {
    let activeSubmenu = 
            this.state.activeSubmenu && this.state.activeSubmenu.nextSibling
              ? this.state.activeSubmenu.nextSibling.querySelectorAll('*') 
              : null,
        activeSubmenuBtn = this.state.activeSubmenu,
        bool = activeSubmenu ? true : false;

    if (activeSubmenu) {
      activeSubmenu.forEach(x => {
        if (e.target === x || e.target === activeSubmenuBtn) {
          bool = false;
        }
      });
    }

    if(bool) {
      this.toggleSubmenu(e, null);
    }
  }

  render() {
    return (
      <MainMenuAnimated 
        menu={this.props.menu}
        toggleSubmenu={this.toggleSubmenu}
        activeSubmenuName={this.state.activeSubmenuName}
        firstToggle={this.state.firstToggle}
        turnOff={this.state.turnOff}
      />
    );
  }

};