import React from 'react';
import { Link } from 'gatsby';
import GlobalStyle from '../styles/global';
import { Spring } from 'react-spring/renderprops';
import styled from 'styled-components';
import { FaPhone } from 'react-icons/fa';
import { colors, above } from '../styles/utilities';
import Logo from '../components/Logo';

class DefaultLayout extends React.Component {
  // default layout
  state = {
    showMainNavShelf: false
  }

  toggleMainNavShelf = () => {
    if ( ! this.state.showMainNavShelf ) {
      this.setState({showMainNavShelf: true})
    }
    else {
      this.setState({showMainNavShelf: false})
    }
  }

  componentDidMount() {
    if ( typeof window.tille.trackGa !== "undefined" ) {
      window.tille.trackGa.init({
        DEBUG: false,
        clickables: [
          {
            selector: '.ga-on-click-and-enter',
            events: ['click']
          }
    
        ],
      });
    }
  }

  render() {

    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        toggleMainNavShelf: this.toggleMainNavShelf
      });
    });

    const menu = (m) => {
      return m.map((mi, idx) => {
        if (mi.link !== undefined) {
          if (mi.link_type && mi.link_type === 'external') {
            return <li key={idx}><a href={mi.link} target="_blank" rel="noopener noreferrer">{mi.name}</a></li>
          }
          return <li key={idx}><Link to={mi.link}>{mi.name}</Link></li>
        }
        else if (mi.sub_menu !== undefined) {
          return (
            <ul key={idx}>
              {menu(mi.sub_menu)}
            </ul>
          )
        }
        return null;
      });
    };

    return (
      <div className={this.props.className}>
        <GlobalStyle />
        <div
          style={
            this.state.showMainNavShelf
            ? {
              position: 'fixed',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0
            }
            : {}
          }
          onClick={
            this.state.showMainNavShelf
            ? this.toggleMainNavShelf
            : null
          }
        >
          { children }
        </div>
        <Spring
          from={{transform: 'translate3d(-105%,0,0)'}}
          to={{transform: this.state.showMainNavShelf ? 'translate3d(0,0,0)' : 'translate3d(-105%, 0, 0'}}
        >
          {props => (
            <div className="shelf--main-nav" style={props}>
              <div className="logo-bg">
                <Logo />
              </div>
              <nav
                className="mainnav--mobile"
              >
                <ul>
                  <Link to="/">Home</Link>
                  {menu(this.props.menu.frontmatter.menu)}
                </ul>
              </nav>
              <div>
                <a 
                  className="phone phone--styled ga-on-click-and-enter"
                  href={`tel:${this.props.meta.phoneNumber}`}
                  ga='[
                    "event", "click", {
                      "event_category": "phone",
                      "event_label": "shelf-menu-phone"
                    }
                  ]'
                >
                  <FaPhone />
                  <span>{this.props.meta.phoneNumber}</span>
                </a>
              </div>
            </div>
          )}
        </Spring>
        <a
          href={`tel:${this.props.meta.phoneNumber}`}
          className="fixed-phone--mobile ga-on-click-and-enter"
          ga='[
            "event", "click", {
              "event_category": "phone",
              "event_label": "fixed-footer-phone-button"
            }
          ]'
        >
          <FaPhone />
        </a>
      </div>

    )
  }

}

export default styled(DefaultLayout)`
  position: relative;
  .shelf--main-nav {
    background-color: ${colors.grayLight};
    bottom: 0;
    box-shadow: 0 0 1px #000;
    padding: 15px;
    position: fixed;
    top: 0;
    transition: left 0.2s ease-in-out;
    width: 65%;
  }
  .logo-bg {
    background-color: ${colors.purpleMid};
    border-radius: 2px;
    padding: 15px;
    .gatsby-image-wrapper {
      max-width: 100%;
      img {
        max-width: 100%;
      }
    }
  }
  .mainnav--mobile {
    ul {
      list-style: none;
      margin: 15px 0;
      padding: 0;
    }
    li {
      margin: 0;
    }
    a {
      background-color: #fff;
      border: 0;
      border-radius: 2px;
      color: ${colors.purpleDark};
      display: block;
      font-size: 1rem;
      margin-bottom: 15px;
      max-width: 100%;
      padding: 15px;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      width: 100%;

      &:active,
      &:focus,
      &:hover {
        color: #fff;
        background-color: ${ colors.purpleDark };
      }
    }
  }
  .phone--styled {
    align-items: center;
    background-color: ${colors.greenLight};
    border-radius: 1px;
    color: #fff;
    display: inline-flex;
    padding: 15px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:active,
    &:focus,
    &:hover {
      color: #fff;
      background-color: ${ colors.blueBright };
    }

    svg {
      margin-right: 10px;
      transform: scaleX(-1);
    }
  }

  .fixed-phone--mobile {
    align-items: center;
    background-color: ${colors.greenLight};
    border-radius: 50%;
    bottom: 30px;
    box-shadow: 0 0 5px #000;
    color: #fff;
    display: flex;
    font-size: 2rem;
    height: 75px;
    justify-content: center;
    position: fixed;
    right: 30px;
    width: 75px;

    ${above.lg`
      display: none;
    `}

    a {
      color: #fff;
    }
  }

  .banner--update--good {
    background-color: ${colors.greenLight};
    color: ${colors.greenDark};
    text-align: center;
    padding: 1rem;

    p {
      margin: 0;
    }
  }
`;