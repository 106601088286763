import { createGlobalStyle } from 'styled-components';
import { above, colors } from './utilities';
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.css';

const GlobalStyle = createGlobalStyle`

  color: ${ colors.purpleDark };

  body {
    font-size: 1.125rem;
  }

  h1, h2, h3, h4, h5, h6, 
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: ${ colors.purpleDark };
  }

  .overlay {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
  }

  .nav--inline {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    li {
      display: inline-block;
      margin-bottom: 0;

      a {
        padding: 10px;
        margin: 0 5px;
        transition: all 0.5s ease-in-out !important;
        border-radius: 1px;
        color: #fff;
        display: inline-block;

        &:active,
        &:focus,
        &:hover {
          background-color: #fff;
          text-decoration: none;
          color: ${ colors.purpleDark };
        }
      }
    }
  }

  .list--inline {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
    }
  }

  .btn {
    display: inline-block;
    padding: 15px;
    border-radius: 1px;
    transition: all 0.2s ease-in-out;
  }

  .btn--green {
    background-color: ${ colors.greenLight };
    color: #fff;

    &:active,
    &:focus,
    &:hover {
      color: #fff;
      background-color: ${ colors.blueBright };
    }

  }

  .btn--purple {
    background-color: ${ colors.purpleDark };
    color: #fff;

    &:active,
    &:focus,
    &:hover {
      color: #fff;
      background-color: ${ colors.blueBright };
    }

  }

  .main-nav__phone {
    margin-left: 2rem;
  }

  .main-nav__phone a {
    border: 1px solid #fff;
    transition: all 0.5s ease-in-out !important;
    font-size: 1.25rem;
  }

  .phone {
    ${above.lg`
      pointer-events: none;      
    `}
  }

  .color--red {
    color: ${colors.red};
  }

`;

export default GlobalStyle;