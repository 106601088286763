import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

export default () => (

  <StaticQuery
    query={graphql`
      {
        imageSharp(fluid: {originalName: {eq: "logo-250x31-transparent-bg.png"}}) {
          fluid(quality:100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={data => (
      <Img
        loading="eager"
        alt="Tille Eye Care" fluid={data.imageSharp.fluid} 
        style={{
          width: '250px'
        }}
        imgStyle={{
          height: 'auto',
          width: '250px'
        }}
        fadeIn={false}
        placeholderStyle={{
          display: 'none'
        }}
      />
    )}
  />

)